package com.steamstreet.vegasful.browser.public

import com.steamstreet.vegasful.browser.account.Account.initAuth
import com.steamstreet.vegasful.browser.account.Account.initializeAccountModules
import com.steamstreet.vegasful.browser.account.initAccountMenu
import com.steamstreet.vegasful.browser.account.onReady
import kotlinx.browser.document
import org.w3c.dom.CustomEvent
import org.w3c.dom.Element
import org.w3c.dom.asList

/**
 * Initialize client application modules.
 */
fun initializeModules(only: String? = null) {
    document.addEventListener("RunVegasfulModule", { event ->
        val detail = event as CustomEvent
        val module = detail.detail.asDynamic().module as? String
        if (module != null) {
            initializeModules(module)
        }
    })

    document.querySelectorAll("[data-module]").asList().forEach {
        (it as? Element)?.let { element ->
            if (element.getAttribute("data-module-initialized") != "1") {
                element.getAttribute("data-module")?.let { module ->
                    if (only == null || module == only) {
                        modules[module]?.invoke(element)
                        element.setAttribute("data-module-initialized", "1")
                    }
                }
            }
        }
    }
}

/**
 * The mapping of modules.
 */
private val modules = mapOf<String, (Element) -> Unit>(
    "global-search" to ::globalSearch,
    "calendar-selection" to ::calendarSelector,
    "event-scroller" to ::eventScroller,
    "ios-webapp-back" to ::iosWebAppBack,
    "event-tag-filter" to ::eventTagFilter,
    "search-results" to ::searchResults
)

fun main() {
    onReady {
        initAuth()
        initAccountMenu()
        initializeModules()
        initializeAccountModules()
    }
}