package com.steamstreet.vegasful.browser.public

import com.steamstreet.vegasful.browser.account.Account
import com.steamstreet.vegasful.browser.account.loginDialog
import com.steamstreet.vegasful.browser.appScope
import com.steamstreet.vegasful.css.css
import kotlinx.browser.document
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.css.em
import kotlinx.css.fontSize
import kotlinx.html.classes
import kotlinx.html.dom.append
import kotlinx.html.js.i
import org.w3c.dom.Element
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.events.KeyboardEvent
import org.w3c.dom.url.URL
import kotlin.time.Duration.Companion.milliseconds

/**
 * Initialize the global search
 */
fun globalSearch(element: Element) {
    val input = (element.querySelector("input") as? HTMLInputElement)
    fun search(text: String) {
        document.location?.href = "${document.location?.origin}/search?searchText=${encodeURIComponent(text)}"
    }

    input?.addEventListener("keydown", { event ->
        val value = (event.target as? HTMLInputElement)?.value
        val code = (event as KeyboardEvent).keyCode
        if (code == 13 && !value.isNullOrBlank()) {
            search(value)
        }
    })

    element.querySelector("span")?.addEventListener("click", {
        input?.value?.takeIf { it.isNotBlank() }?.let {
            search(it)
        }
    })

    val searchText = document.location?.href?.let { URL(it) }?.searchParams?.get("searchText")
    if (searchText != null) {
        input?.value = searchText
    }

    input?.focus()
}

/**
 * Executes a search and sets the results
 */
fun searchResults(element: Element) {
    val query = element.getAttribute("data-search-input")
    val isLoggedIn = Account.isLoggedIn()
    if (isLoggedIn) {
        if (!query.isNullOrBlank()) {
            appScope.launch {
                val resultsContainer = element.querySelector(".search-results-container")
                var done = false

                launch(Dispatchers.Main) {
                    delay(700.milliseconds)
                    if (!done) {
                        resultsContainer?.append {
                            i {
                                classes = setOf("fa-solid", "fa-cog", "fa-spin")
                                css {
                                    fontSize = 2.em
                                }
                            }
                        }
                    }
                }

                val result = Account.load("/search/results?searchText=${encodeURIComponent(query)}")
                done = true
                if (result != null) {
                    resultsContainer?.let {
                        it.innerHTML = result
                    }
                }
            }
        }
    } else {
        element.append {
            loginDialog("Login to search for Vegasful events", "Login")
        }
    }
}