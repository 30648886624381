package com.steamstreet.vegasful.browser.account

import com.steamstreet.vegasful.browser.account.intelligence.Intelligence
import com.steamstreet.vegasful.browser.account.subscriptions.EntityInteractionsController
import com.steamstreet.vegasful.browser.account.subscriptions.myGuide
import com.steamstreet.vegasful.browser.appScope
import io.ktor.client.*
import io.ktor.client.engine.js.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.html.dom.append
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.w3c.dom.*
import org.w3c.dom.url.URLSearchParams
import kotlin.js.Date
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds

typealias Renderer = suspend (Element) -> Unit


val config: dynamic by lazy {
    window["vegasful"]
}

object Account {
    private var token: Token? = null
    private val jsonClient = HttpClient(JsClient())
    private val authJson = Json {
        ignoreUnknownKeys = true
    }

    val modules: Map<String, Renderer> = mapOf(
        "entity-interactions" to {
            coroutineScope {
                EntityInteractionsController(appScope, it).run()
            }
        },
        "my-guide" to ::myGuide,
        "login" to { element ->
            URLSearchParams(window.location.search).let {
                element.append {
                    loginDialog("Login to your Vegasful account", "Login")
                }
            }
        },
        "post-auth" to {
            postAuth()
        },
        "account-delete" to {
            println("Found account-delete")
            it.append {
                accountDelete(appScope)
            }
        },
        "intelligence" to {
            Intelligence(it, appScope).run()
        }
    )

    private fun executeModule(element: Element, module: String) {
        val moduleFunction = modules[module]
        if (moduleFunction != null) {
            appScope.launch {
                moduleFunction.invoke(element)
            }
        }
    }

    fun initializeAccountModules() {
        document.querySelectorAll("[data-account-module]").asList().forEach { node ->
            (node as? Element)?.let { element ->
                element.getAttribute("data-account-module")?.let {
                    executeModule(element, it)
                }
            }
        }
    }

    fun run() {
        appScope.launch {
            initAuth()
            initAccountMenu()
            initializeAccountModules()
        }
    }

    fun navigate(url: String) {
        window.location.href = url
    }

    suspend fun initAuth() {
        token = localStorage.get(AUTH_TOKEN_KEY)?.let {
            authJson.decodeFromString<Token>(it)
        }
        GraphQL.endPoint = config["graphQlBase"] as String
        GraphQL.tokenFetcher = {
            getToken()
        }

        // refresh the token as necessary
        if (token != null && isTokenExpired()) {
            if (attemptRefresh()) {
                // reload the page if we got a new token.
                document.location?.reload()
            }
        }
    }

    private fun isTokenExpired(): Boolean {
        return (token?.expiration ?: Instant.DISTANT_PAST) < Clock.System.now()
    }

    private suspend fun attemptRefresh(): Boolean {
        return token?.refresh_token?.let {
            authorize(TokenType.REFRESH, it)
        } ?: false
    }

    /**
     * Get the token, refreshing as necessary.
     */
    private suspend fun getToken(): String? {
        if (isTokenExpired()) {
            attemptRefresh()
        }
        return token?.access_token?.takeIf { !isTokenExpired() }
    }

    fun login(returnUrl: String? = null) {
        if (returnUrl != null) {
            localStorage.setItem(LOGIN_REDIRECT_KEY, returnUrl)
        }
        navigate("/login")
    }

    /**
     * Authenticate the user with the given provider through redirection.
     */
    fun authenticate(provider: String?) {
        val url = buildUrl("https://${config["loginDomain"]}/oauth2/authorize") {
            parameter("client_id", config["authClientId"])
            parameter("response_type", "code")
            parameter("redirect_uri", "${window.location.origin}/post-auth")

            if (provider != null) {
                parameter("identity_provider", provider)
            }
        }
        window.location.href = url.toString()
    }

    /**
     * Authorize using an authorization code.
     */
    suspend fun authorize(type: TokenType, code: String): Boolean {
        val response = jsonClient.submitForm(
            "https://${config["loginDomain"]}/oauth2/token",
            formParameters = Parameters.build {
                append("grant_type", type.type)
                append("client_id", config["authClientId"] as String)
                if (type == TokenType.AUTHORIZATION_CODE) {
                    append("redirect_uri", "${window.location.origin}/post-auth")
                }
                append(type.paramName, code)
            })
        if (!response.status.isSuccess()) {
            return false
        }

        val tokenString = response.bodyAsText()

        token = authJson.decodeFromString<Token>(tokenString)
        val expiration = Clock.System.now().plus((token?.expires_in ?: 0).seconds).minus(1.minutes)
        token = token?.copy(
            expiration = expiration,
            // Cognito doesn't return us the refresh token on refresh, so we need to save it
            refresh_token = token?.refresh_token ?: code.takeIf { type == TokenType.REFRESH }
        )
        localStorage.setItem(AUTH_TOKEN_KEY, authJson.encodeToString(token))
        saveCookie()

        return true
    }

    private fun clearCookie() {
        println("Clear cookie")
        document.cookie =
            """VegasfulToken=${token?.access_token ?: ""}; expires=${Date(0).toUTCString()}; path=/; Secure; SameSite=Strict`"""
    }

    private fun saveCookie() {
        val expiration = token?.expiration
        if (expiration == null) {
            clearCookie()
        } else {
            val expirationDate = Date(expiration.toEpochMilliseconds()).toUTCString()
            val cookieStr =
                "VegasfulToken=${token?.access_token ?: ""}; expires=$expirationDate; path=/; SameSite=Strict"
            document.cookie = cookieStr
        }
    }

    private fun hasAuthCookie(): Boolean {
        return document.cookie.split(";").map {
            it.trim()
        }.any { it.startsWith("authToken=") }
    }

    fun getLoginReturnUrl(): String? {
        return localStorage.getItem(LOGIN_REDIRECT_KEY)
    }

    fun logout() {
        clearCookie()
        localStorage.removeItem(AUTH_TOKEN_KEY)
        val url = buildUrl("https://${config["loginDomain"]}/logout") {
            parameter("client_id", config["authClientId"])
            parameter("logout_uri", "${window.location.origin}/logout")
        }
        window.location.href = url.toString()
    }

    fun isLoggedIn(): Boolean {
        return (token?.let {
            val tokenExpires = it.expiration ?: Instant.DISTANT_PAST
            if (tokenExpires > Clock.System.now()) {
                true
            } else {
                // if it's expired but we have a refresh token, we're also good.
                it.refresh_token != null
            }
        } ?: false).also { isLoggedIn ->
            if (isLoggedIn) {
                if (!hasAuthCookie()) {
                    saveCookie()
                }
            } else if (hasAuthCookie()) {
                clearCookie()
            }
        }
    }

    /**
     * Load data from the given URL.
     */
    suspend fun load(url: String, requiresLogin: Boolean = false): String? {
        val token = getToken()
        if (requiresLogin && token == null) {
            return null
        }

        return try {
            jsonClient.get(url) {
                headers {
                    token?.let {
                        append("Authorization", "Bearer $it")
                    }
                }
            }.bodyAsText()
        } catch (t: Throwable) {
            t.printStackTrace()
            null
        }
    }
}

fun buildUrl(baseUrl: String? = null, builder: URLBuilder.() -> Unit): Url =
    (baseUrl?.let { URLBuilder(it) } ?: URLBuilder()).apply(builder).build()

fun URLBuilder.parameter(key: String, value: String) {
    parameters.append(key, value)
}

fun onReady(cb: suspend () -> Unit) {
    if (document.readyState === DocumentReadyState.COMPLETE || document.readyState === DocumentReadyState.INTERACTIVE) {
        appScope.launch {
            cb()
        }
    } else {
        document.addEventListener("DOMContentLoaded", {
            appScope.launch {
                cb()
            }
        })
    }
}


private const val LOGIN_REDIRECT_KEY = "vegasful_login_redirect"
private const val AUTH_TOKEN_KEY = "vegasful_token"

enum class TokenType(val type: String, val paramName: String) {
    REFRESH("refresh_token", "refresh_token"),
    AUTHORIZATION_CODE("authorization_code", "code")
}

@Serializable
public data class Token(
    val access_token: String,
    val refresh_token: String? = null,
    val id_token: String,
    val token_type: String,
    val expires_in: Int,
    var expiration: Instant? = null
)