@file:Suppress("JSON_FORMAT_REDUNDANT_DEFAULT")

package com.steamstreet.vegasful.graphql.api

import com.steamstreet.graphkt.ID
import kotlin.Boolean
import kotlin.Float
import kotlin.Int
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlin.time.Duration
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalTime
import kotlinx.datetime.serializers.InstantIso8601Serializer
import kotlinx.datetime.serializers.LocalDateIso8601Serializer
import kotlinx.datetime.serializers.LocalTimeIso8601Serializer
import kotlinx.serialization.Contextual
import kotlinx.serialization.Serializable
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.Json
import kotlinx.serialization.modules.SerializersModule

/**
 *  The input to a conversation
 */
@Serializable
data class ConversationInput(
  val query: String
)

@Serializable
data class GlobalSearchInput(
  val text: String
)

@Serializable
data class PerformerSearch(
  val text: String? = null
)

@Serializable
data class ArticleSearchInput(
  val maxResults: Int? = null,
  val links: List<String>? = null
)

@Serializable
data class EventSearch(
  @Contextual
  val start: EventInstant? = null,
  @Contextual
  val end: EventInstant? = null,
  val pageSize: Int? = null,
  val pageToken: String? = null
)

@Serializable
data class EventAttributeInput(
  val key: String,
  val value: String
)

@Serializable
data class SystemEventInput(
  val attributes: List<EventAttributeInput>
)

@Serializable
data class SubscriptionInput(
  val userId: String? = null,
  val entityPath: String
)

@Serializable
data class ClearUserDataInput(
  val userEmail: String? = null
)

@Serializable
data class LikeInput(
  val entityPath: String
)

@Serializable
data class ShareInput(
  val entityPath: String
)

@Serializable
data class ImageUpload(
  val data: String? = null,
  val type: String? = null,
  val uri: String? = null
)

/**
 *  The update information
 */
@Serializable
data class VenueUpdateInput(
  val name: String? = null,
  val description: String? = null,
  val address: AddressInput? = null,
  val location: GPSCoordinatesInput? = null,
  val links: List<SocialLinkInput>? = null,
  val ticketsUrl: String? = null,
  val parent: String? = null,
  val tags: List<String>? = null,
  val images: List<String>? = null,
  val aliases: List<String>? = null,
  val redirect: String? = null,
  val status: VenueStatus? = null,
  val rankingScore: Int? = null
)

@Serializable
data class SocialLinkInput(
  val type: String,
  val locator: String? = null
)

@Serializable
data class AddressInput(
  val street: String? = null,
  val city: String? = null,
  val state: String? = null,
  val zip: String? = null,
  val country: String? = null
)

@Serializable
data class GPSCoordinatesInput(
  val latitude: Float? = null,
  val longitude: Float? = null
)

/**
 *  The update information
 */
@Serializable
data class PerformerUpdateInput(
  val name: String? = null,
  val description: String? = null,
  val aliases: List<String>? = null,
  val tags: List<String>? = null,
  val images: List<String>? = null,
  val wikiIdentifier: String? = null,
  val rankingScore: Int? = null
)

@Serializable
data class MergeInput(
  val target: ID,
  val sources: List<ID>
)

@Serializable
data class EventUpdateInput(
  val parent: String? = null,
  val name: String? = null,
  val description: String? = null,
  val venue: String? = null,
  @Contextual
  val date: EventDate? = null,
  @Contextual
  val time: EventTime? = null,
  val duration: Int? = null,
  @Contextual
  val endDate: EventDate? = null,
  @Contextual
  val endTime: EventTime? = null,
  val ticketsUrl: String? = null,
  val sourceUrl: String? = null,
  val aliases: List<String>? = null,
  val images: List<String>? = null,
  val excludedImages: List<String>? = null,
  val excludedTags: List<String>? = null,
  val performers: List<String>? = null,
  val excluded: Boolean? = null,
  val rankingScore: Int? = null,
  val suggestedPerformers: List<String>? = null,
  val eventTags: List<EventTagInput>? = null
)

@Serializable
data class EventTagInput(
  val id: ID,
  val confidence: Int? = null
)

@Serializable
data class ArticleInput(
  val title: String? = null,
  val summary: String? = null,
  val content: String? = null,
  val images: List<String>? = null,
  val links: List<String>? = null,
  @Contextual
  val startTs: EventInstant? = null,
  @Contextual
  val endTs: EventInstant? = null
)

@Serializable
data class TagUpdateInput(
  val name: String? = null,
  val parent: String? = null,
  val description: String? = null,
  val images: List<String>? = null,
  val shadow: Boolean? = null,
  val implied: List<String>? = null,
  val pageTitle: String? = null,
  val rankingScore: Int? = null,
  val aiPromptInput: String? = null
)

@Serializable
data class LoaderConfiguration(
  val name: String? = null,
  val module: ID? = null,
  val schedule: String? = null,
  val parameters: String? = null
)

@Serializable
data class LoaderPublishInput(
  val data: String
)

@Serializable
data class LoaderExecutionInput(
  val data: String? = null
)

enum class ConversationStatus {
  RUNNING,

  COMPLETE,

  FAILED
}

enum class VenueStatus {
  OPEN,

  CLOSED_TEMPORARILY,

  CLOSED_PERMANENTLY
}

enum class InteractionType {
  LIKE,

  FOLLOW,

  SHARE,

  CALENDAR
}

typealias EventDate = LocalDate

typealias EventTime = LocalTime

typealias EventInstant = Instant

typealias EventDuration = Duration

val serializerModule: SerializersModule = SerializersModule {
  contextual(LocalDate::class, LocalDateIso8601Serializer)
  contextual(LocalTime::class, LocalTimeIso8601Serializer)
  contextual(Instant::class, InstantIso8601Serializer)
  contextual(Duration::class, DurationSerializer)
}


internal val json: Json = Json {
  serializersModule = serializerModule
  ignoreUnknownKeys = true
}

