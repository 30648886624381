package com.steamstreet.vegasful.html.components

import com.steamstreet.vegasful.css.*
import kotlinx.css.*
import kotlinx.html.FlowContent
import kotlinx.html.HtmlBlockTag
import kotlinx.html.div
import kotlinx.html.unsafe


/**
 * Render the main content area, which constrains the width to a maximum size.
 */
fun FlowContent.mainContentArea(block: FlowContent.() -> Unit) {
    div {
        css {
            maxWidth = VegasfulStyles.mainContentMaxWidth
            zIndex = 60
            position = Position.relative
            margin(0.px)

            whenWiderThanPortraitPhone {
                margin(0.px, 20.px)
            }

            whenReallyWide {
                margin(0.px, LinearDimension.auto)
            }
        }
        div {
            block()
        }
    }
}

class SplitContentArea {
    internal var l: (HtmlBlockTag.() -> Unit)? = null
    internal var r: (HtmlBlockTag.() -> Unit)? = null

    fun left(block: HtmlBlockTag.() -> Unit) {
        l = block
    }

    fun right(block: HtmlBlockTag.() -> Unit) {
        r = block
    }
}

/**
 * Content area where there is a large block and a small block, which will appear on top of each
 * other in mobile.
 */
fun FlowContent.splitContentArea(block: SplitContentArea.() -> Unit) {
    val config = SplitContentArea().apply(block)
    mainContentArea {
        div {
            css {
                display = Display.grid
                gridTemplateColumns = GridTemplateColumns("1fr")


                whenWiderThanPortraitPhone {
                    marginTop = 10.px
                    gridTemplateColumns = GridTemplateColumns("1fr 35%")
                    gridAutoRows = GridAutoRows("1fr")
                    gap = 20.px
                }
            }

            div {
                config.l?.invoke(this)
            }
            div {
                config.r?.invoke(this)
            }
        }
    }
}

/**
 * Renders a rich description in a div, typically on the left side of a split content area
 */
fun FlowContent.richDescription(description: String?, boldLinks: Boolean = false) {
    if (description == null) return

    div {
        css {
            padding(0.px, 10.px)
            marginBottom = 20.px
            whenWiderThanPortraitPhone {
                padding(0.px)
            }
            lineHeight(1.5.em)
            fontWeight = FontWeight.w400
            if (boldLinks) {
                a {
                    fontWeight = FontWeight.w700
                }
            }
        }
        unsafe {
            +description
        }
    }
}